import React, { useState } from "react"
import next from "../../static/next.svg"
import prev from "../../static/prev.svg"
/*
urls = [
	{type: video, url:"cdn.assets.smth"},
	{type: image, url:"cdn.assets.smth"},
]
*/

const ImageSlide = ({ url }) => (
  <img className="slider__img" src={url} alt="Sample from project" />
)
const VideoSlide = ({ url }) => (
  <video autoplay controls className="slider__img" alt="Sample from project">
    <source src={url} type="video/mp4" />
    <source src={url} type="video/mov" />
    <source src={url} type="video/ogg" />
    no video support
  </video>
)

const Slider = ({ urls, className }) => {
  let [i, seti] = useState(0)

  if (!urls) {
    return <div className="slider--empty"></div>
  }
  if (urls.length < 2) {
    return (
      <div className={`slider ${className}`}>
        {urls[0].type === "video" ? (
          <VideoSlide url={urls[i].url} />
        ) : (
          <ImageSlide url={urls[i].url} />
        )}
      </div>
    )
  }
  return (
    <div className={`slider ${className}`}>
      {urls[i].type === "video" ? (
        <VideoSlide url={urls[i].url} />
      ) : (
        <ImageSlide url={urls[i].url} />
      )}
      <button
        className="slider__prev slider__btn"
        onClick={i === 0 ? () => seti(urls.length - 1) : () => seti(i - 1)}
      >
        <img src={prev} alt="previous icon" />
      </button>
      <button
        className="slider__next slider__btn"
        onClick={i === urls.length - 1 ? () => seti(0) : () => seti(i + 1)}
      >
        <img src={next} alt="next icon" />
      </button>
    </div>
  )
}

export default Slider
