import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import { LangContext } from "../hooks/lang.js"
import Slider from "./slider.js"
import flecheBas from "../../static/flècheBas.svg"

const Projets = () => {
  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null
  let french = langContext && langContext.lang && langContext.lang === "fr-CA"
  let [ dynamicData, setDynamicData ] = useState(null)

  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "projet", locale: lang })
      .then(p => {
        let data = p.items.map(pp => {
          return ({
            id: pp.sys.id,
            order: pp.fields.order,
            titre: pp.fields.titre || null,
            sousTitre: pp.fields.sousTitre || null,
            descriptionPreview: pp.fields.descriptionPreview || null,
            imagesPreview:
              (pp.fields.imagesPreview &&
                pp.fields.imagesPreview.map(ppp => ppp.fields.file.url)) ||
              null,
            videosPreview:
              (pp.fields.videosPreview &&
                pp.fields.videosPreview.map(ppp => ppp.fields.file.url)) ||
              null,
          })
        })

        let cleanData = [];
        data.forEach((d) => {
          if (d.order) {
            cleanData.push(d)
          }
        })

        cleanData.forEach(d => {
          if (d.imagesPreview) {
            d.imagesPreview.forEach(di => {
              const img = new Image()
              img.src = di.url
            })
          }
        })

        cleanData = cleanData.sort((a, b) => parseInt(a.order) - parseInt(b.order))
        setDynamicData(cleanData)
      })
  }, [ lang ])

  if (!dynamicData) {
    return <div>Chargement</div>
  }
  return (
    <section className="projets">
      <div className="header">
        <img src={flecheBas} alt="downwards arrow icon" />
        <h2>{french ? "Projets Récents" : "Recent Projects"}</h2>
        <img src={flecheBas} alt="downwards arrow icon" />
      </div>

      {dynamicData.map((p, i) => (
        <div className="projet" key={p.id}>
          <Link to={`/projet/${p.id}`} className="projet__info">
            <h2>{p.titre && p.titre}</h2>
            <h3>{p.sousTitre && p.sousTitre}</h3>
            {p.descriptionPreview && <p>{p.descriptionPreview}</p>}
          </Link>
          {p.imagesPreview && !p.videosPreview && (
            <Slider
              className="slider"
              key={i}
              urls={
                p.imagesPreview &&
                p.imagesPreview.map(url => ({ type: "image", url }))
              }
            />
          )}
          {!p.imagesPreview && p.videosPreview && (
            <Slider
              className="slider"
              key={i}
              urls={p.videosPreview.map(url => ({ type: "video", url }))}
            />
          )}
          {p.imagesPreview && p.videosPreview && (
            <Slider
              className="slider"
              key={i}
              urls={[
                ...p.imagesPreview.map(url => ({ type: "image", url })),
                ...p.videosPreview.map(url => ({ type: "video", url })),
              ]}
            />
          )}
        </div>
      ))}
    </section>
  )
}

export default Projets
