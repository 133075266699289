import React from "react"

import gradient from "../../static/gradient.png"
import logo from "../../static/logo-animated.gif"

class Overlay extends React.Component {
  constructor() {
    super()
    this.state = { hide: false }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
    setTimeout(() => this.setState({ hide: true }), 4000)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll(e) {
    this.setState({
      hide: true,
    })
  }

  render() {
    return (
      <>
        {!this.state.hide ? (
          <>
            <img
              onClick={() => this.setState({ hide: true })}
              onKeyPress={() => this.setState({ hide: true })}
              className="overlay__bck"
              src={gradient}
              alt="Decorative gradient"
            />
            <img
              onClick={() => this.setState({ hide: true })}
              onKeyPress={() => this.setState({ hide: true })}
              className="overlay__animation"
              src={logo}
              alt="Animated brand logo"
            />
          </>
        ) : (
          <> {this.props.children}</>
        )}
      </>
    )
  }
}

export default Overlay
