import React, { useContext, useEffect, useState } from "react"
import { Link } from "gatsby"
import Page from "../components/page"
import { LangContext } from "../hooks/lang.js"
import Overlay from "../components/overlay.js"
import Projets from "../components/ProjetsRecents.js"
import flammeRose from "../../static/flammeRose.svg"
import logotype from "../../static/logotype.svg"

const Home = data => {
  
  let langContext = useContext(LangContext) || null;
  let lang = langContext ? langContext.lang : null

  let [dynamicData, setDynamicData] = useState(null)
  useEffect(() => {
    window.contentfulClient
      .getEntries({ content_type: "pageDaccueil", locale: lang })
      .then(p => {
        let dd = p.items[0].fields
        dd.liensSociaux = dd.liensSociaux.map(ls => ls.fields)
        setDynamicData(dd)
      })
  }, [lang])

  if (dynamicData) {
    return (
      <Overlay>
        <section className="hero">
          <img className="logotype" src={logotype} alt="Brand logotype" />
          <h2>{dynamicData.header}</h2>
          <img className="flamme" src={flammeRose} alt="Pink Flame graphic" />
        </section>
        <section className="two-links-horizontal">
          <div>
            <h3>{dynamicData.optionUn}</h3>
            <Link to="/collaborateurs">{dynamicData.optionUnLien}</Link>
          </div>
          <div>
            <h3>{dynamicData.optionDeux}</h3>
            <Link to="/services">{dynamicData.optionDeuxLien}</Link>
          </div>
        </section>
        <Projets />
        <div className="contact contact--home">
          <section className="contact__section2">
            <div className="contact__social-links">
              {dynamicData.liensSociaux.map(ls => (
                <a
                  key={ls.nom}
                  className="collaborateur__social-link"
                  href={ls.lien}
                >
                  {ls.nom}
                </a>
              ))}
            </div>
          </section>
        </div>
      </Overlay>
    )
  } else {
    return <div>Chargement</div>
  }
}

const HomeWithPage = props => (
  <Page>
    <Home {...props} />
  </Page>
)
export default HomeWithPage
